<template>
  <div class="customer-list-page w-100">
    <basic-subheader>
      <template v-slot:actions>
        <b-dropdown
          id="dropdown-right dropdown-form"
          class="customer-list-page__dropdown-form-filter mr-2"
          no-caret
          right
          ref="dropdownFormFilter"
        >
          <template #button-content>
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </template>
          <h6 class="d-flex align-items-center mb-0 p-4">
            <span class="svg-icon mr-3">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </h6>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-form>
            <b-container class="p-0">
              <b-row>
                <b-col>
                  <basic-input
                    label="Mã bài học"
                    placeholder="Nhập mã bài học"
                    name="code"
                  ></basic-input>
                </b-col>
                <b-col>
                  <basic-input
                    label="Tiêu đề bài học"
                    placeholder="Nhập tiêu đề bài học"
                    name="name"
                  ></basic-input>
                </b-col>
                <b-col>
                  <basic-select
                    label="Coach"
                    placeholder="---Chọn coach---"
                    name="coach"
                    :options="[]"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-checkbox> Lọc nhóm Inactive </b-checkbox>
                </b-col>
              </b-row>
            </b-container>
          </b-dropdown-form>
          <b-dropdown-divider></b-dropdown-divider>
          <div class="d-flex align-items-center justify-content-lg-end m-0 p-4">
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click.stop="onClickResetFilterButton"
            >
              <span class="svg-icon">
                <inline-svg
                  src="/media/svg/icons/Neolex/Basic/refresh-cw.svg"
                />
              </span>
              Reset bộ lọc
            </b-button>
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click="onClickFilterButton"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
              </span>
              Lọc dữ liệu
            </b-button>
          </div>
        </b-dropdown>
      </template>
    </basic-subheader>
    <b-container fluid class="customer-list-page__body mb-6 mt-6">
      <b-row>
        <b-col>
          <div class="card card-custom gutter-b">
            <div class="card-body p-0">
              <b-tabs card class="customer-list-page__body__tabs">
                <b-tab>
                  <template #title>
                    <span>Đang diễn ra</span>
                  </template>
                  <customer-in-progress />
                </b-tab>
                <b-tab>
                  <template #title>
                    <span>Đã kết thúc</span>
                  </template>
                  <customer-finished />
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <customer-add />
  </div>
</template>

<script>
import CustomerAdd from './CustomerAdd';
import CustomerInProgress from './CustomerInProgress';
import CustomerFinished from './CustomerFinished';

export default {
  name: 'CustomerList',
  components: { CustomerAdd, CustomerInProgress, CustomerFinished },
  data() {
    return {};
  },
  methods: {
    onClickAddCustomer() {
      this.$bvModal.show('customer-add-modal');
    },
    onClickResetFilterButton() {
      document.body.click();
    },
    onClickFilterButton() {
      document.body.click();
    },
  },
};
</script>

<style lang="scss">
.customer-list-page {
  .customer-list-page__dropdown-form-filter {
    .dropdown-menu {
      .container {
        width: 850px;
      }
    }
  }
  .customer-list-page__body {
    .customer-list-page__body__tabs {
      a.nav-link {
        border: 0;

        &.active {
          font-weight: 600;
          color: #01645a !important;
          border-bottom: 2px solid #01645a;
        }
      }

      li.nav-item {
        a.nav-link:hover {
          color: #333;
        }
      }
    }
  }

  .customer-list-page__divider {
    border: 1px solid #000000;
    height: 40px;
    transform: rotate(200deg);
  }

  .customer-list-page__block {
    width: 275px;

    .customer-list-page__block__text {
      font-size: 15px;
      font-weight: bold;
    }
  }
}
</style>
