<template>
  <div class="customer-finished">
    <div class="customer-finished__statistic">
      <span class="customer-finished__statistic__text">Tổng học viên: </span>
      <span class="customer-finished__statistic__value">124</span>
    </div>
    <basic-input
      class="customer-finished__search"
      label="Tên khách hàng"
      placeholder="Nhập tên khách hàng"
    />
    <template-table
      :column="column"
      :data="data"
      :paging="paging"
      :tableAction="false"
      :selectAction="false"
      :searchAction="false"
    />
  </div>
</template>

<script>
export default {
  name: 'CustomerFinished',
  data() {
    return {
      column: [
        {
          key: 'customer',
          label: 'Khách hàng',
          sortable: true,
        },
        {
          key: 'actionDropdown',
          label: '',
          sortable: false,
        },
        {
          key: 'age',
          label: 'Tuổi',
          sortable: false,
        },
        {
          key: 'sex',
          label: 'Giới tính',
          sortable: false,
        },
        {
          key: 'dtdTip',
          label: 'ĐTĐ Típ',
          sortable: false,
        },
        {
          key: 'mobile',
          label: 'Số điện thoại',
          sortable: false,
        },
        {
          key: 'startDate',
          label: 'Ngày bắt đầu',
          sortable: false,
        },
        {
          key: 'endDate',
          label: 'Ngày kết thúc',
          sortable: false,
        },
      ],
      data: [],
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
};
</script>

<style lang="scss">
.customer-finished {
  .customer-finished__statistic {
    margin-bottom: 28px;

    .customer-finished__statistic__text {
      font-weight: bold;
      font-size: 18px;
    }

    .customer-finished__statistic__value {
      font-weight: bold;
      font-size: 18px;
    }
  }
  .customer-finished__search {
    width: 350px;
  }
}
</style>
