<template>
  <b-modal id="customer-add-modal" title="Thêm khách hàng" size="xl">
    <b-container>
      <b-row>
        <b-col>
          <b-row>
            <b-col class="py-0">
              <label class="mb-0">Ngày bắt đầu</label>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <basic-date-picker placeholder="Từ ngày" name="startDate" />
            </b-col>
            <b-col>
              <basic-date-picker placeholder="Đến ngày" name="endDate" />
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row>
            <b-col class="py-0">
              <label class="mb-0">Khách hàng</label>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <basic-input
                placeholder="Nhập tên khách hàng hoặc số điện thoại"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <template-table
            :column="column"
            :data="data"
            :paging="paging"
            :searchAction="false"
          >
          </template-table>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
export default {
  name: 'CustomerAdd',
  data() {
    return {
      column: [
        {
          key: 'customer',
          label: 'Khách hàng',
          sortable: true,
        },
        {
          key: 'code',
          label: 'UserCode',
          sortable: false,
        },
        {
          key: 'age',
          label: 'Tuổi',
          sortable: false,
        },
        {
          key: 'dtdTip',
          label: 'DTD Tip',
          sortable: false,
        },
        {
          key: 'phone',
          label: 'Số điện thoại',
          sortable: false,
        },
        {
          key: 'startDate',
          label: 'Ngày bắt đầu',
          sortable: false,
        },
        {
          key: 'endDate',
          label: 'Ngày kết thúc',
          sortable: false,
        },
      ],
      data: [],
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
};
</script>

<style scoped></style>
